import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "Index",
            meta: {
                title: '首页'
            },
            component: () => import('@/views/index'),
        },
        {
            path: '/index',
            meta: {
                title: '首页'
            },
            component: () => import('@/views/index'),
        },
        {
            path: '/about',
            name: "About",
            meta: {
                title: '研究院概况'
            },
            component: () => import('@/views/about/index'),
        },
        {
            path: '/trends',
            name: "Trends",
            meta: {
                title: '研究院动态'
            },
            component: () => import('@/views/trends/index'),
        },
        {
            path: '/achievements',
            name: "Achievements",
            meta: {
                title: '研究成果'
            },
            component: () => import('@/views/achievements/index'),
        },
        {
            path: '/exchanges',
            name: "Exchanges",
            meta: {
                title: '学术交流'
            },
            component: () => import('@/views/exchanges/index'),
        },
        {
            path: '/books',
            name: "Books",
            meta: {
                title: '出版物'
            },
            component: () => import('@/views/books/index'),
        },
        {
            path: '/searchs/:page/:keywords',
            name: "Searchs",
            meta: {
                title: '搜索页面'
            },
            component: () => import('@/views/searchs/index'),
        },
        {
            path: '/detail/:id',
            name: "Detail",
            meta: {
                title: '单页'
            },
            component: () => import('@/views/detail/index'),
        },
        {
            path: '/news_detail/:cid/:id',
            name: "NewsDetail",
            meta: {
                title: '新闻详情'
            },
            component: () => import('@/views/newsDetail/index'),
        },
        {
            path: '/book_detail/:cid/:id',
            name: "BookDetail",
            meta: {
                title: '书籍详情'
            },
            component: () => import('@/views/bookDetail/index'),
        },
        {
            path: "*",
            meta: {
                title: '404'
            },
            component: () => import('@/views/errors/error404.vue'),
        },
        {
            path: "/stop",
            meta: {
                title: '暂停服务'
            },
            component: () => import('@/views/errors/errorStop.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    store.dispatch('getGlobalConfig').then(() => {
        if (store.getters.getMourningMod) {
            document.getElementsByTagName("html")[0].classList.toggle('condoles');
        }
        if (!store.getters.getWebOpenStatus) {
            next('/stop')
        }
    });
    next();
});

export default router;