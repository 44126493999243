import request from '@/utils/request'

/**
 * 获取导航菜单
 * @returns
 */
export async function navigationList (param) {
    return request({
        url: '/api/web/nav',
        method: 'get',
        params: param
    });
}
/**
 * 通过分类ID获取分类详情
 * @returns
 */
export async function classifyDetail (id) {
    return request({
        url: '/api/news/classes/' + id,
        method: 'get'
    });
}
/**
 * 通过分类列表
 * @returns
 */
export async function classifyList (param) {
    return request({
        url: '/api/news/classes',
        method: 'get',
        params: param
    });
}