import { classifyDetail } from "@/api/navigation";
// 动态加载外部JS
export function loadExternalScript(src) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = () => {
        // console.log('Script loaded successfully');
    };
    script.onerror = () => {
        // console.error('Script load error');
    };
    document.head.appendChild(script);
}
// a标签跳转
export function routerLink(url, targets = false) {
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    link.target = targets ? '_blank' : "_self";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 0);
}
/**
 * @description 判断是否为数字
 * @param value
 * @returns {boolean}
 */
export function isNumber(value) {
    const reg = /^[0-9]*$/
    return reg.test(value)
}
/**
 * @description 设置页面SEO
 * @param title     标题
 * @param keyword   关键词
 * @param description   描述
 * @returns {boolean}
 */
export function setPageSeo(title, keyword, description) {
    document.title = title + `_${ this.$store.getters.getWebsiteName }`;
    document.querySelector('meta[name="keywords"]').setAttribute('content', keyword);
    document.querySelector('meta[name="description"]').setAttribute('content', description);
}
/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} filed 孩子节点字段中增加的字段名称
 * @param {*} filedValue 孩子节点字段中增加的字段 默认值
 */
export function handleTree(data, id, parentId, children, filed = "", filedValue) {
    let config = {
        id: id || 'id',
        parentId: parentId || 'parentId',
        childrenList: children || 'children'
    };

    var childrenListMap = {};
    var nodeIds = {};
    var tree = [];

    for (let d of data) {
        let parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
            childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
    }

    for (let d of data) {
        let parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
            tree.push(d);
        }
    }

    for (let t of tree) {
        adaptToChildrenList(t);
    }

    function adaptToChildrenList(o) {
        if (filed) {
            o[filed] = filedValue;
        }
        if (childrenListMap[o[config.id]] !== null) {
            o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
            for (let c of o[config.childrenList]) {
                adaptToChildrenList(c);
            }
        }
    }
    return tree;
}
/**
 * @description 年份生成
 * @param startYear  开始年份
 * @param endYear    结束年份
 */
export function createYears(startYear, endYear) {
    let arr = [];
    endYear = endYear && isNumber(endYear) ? endYear : this.$moment().year();
    for (let i = startYear; i <= endYear; i++) {
        arr.push(i);
    }
    return arr;
}
